import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/fashn-web/fashn-web/components/navbar.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/fashn-web/fashn-web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/fashn-web/fashn-web/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/fashn-web/fashn-web/node_modules/next/font/google/target.css?{\"path\":\"components/footer.tsx\",\"import\":\"Exo_2\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"exo2\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/fashn-web/fashn-web/node_modules/next/font/google/target.css?{\"path\":\"components/footer.tsx\",\"import\":\"Onest\",\"arguments\":[{\"weight\":[\"400\",\"600\"],\"subsets\":[\"latin\"]}],\"variableName\":\"headFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["toast","success"] */ "/home/runner/work/fashn-web/fashn-web/node_modules/sonner/dist/index.mjs");
